// Dependencies
import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/layout'
import Hero from '../components/Hero'
import Services from '../components/Services'
import Helmet from '../components/Helmet'
import Social from '../components/Social'

// Helpers
import { getNavigationProps, getExtraSections } from '../utils'

const ServicesPage = (props) => {
  const langLinks = [
    {
      url: props.path.replace(`/${props.pageContext.lang}`, ''),
      lang: props.pageContext.lang,
      active: true,
    },
    ...get(props, 'data.prismicServicesContent.alternate_languages'),
  ]

  const sections = [
    get(props, 'data.allPrismicTestimonialsContent'),
    get(props, 'data.allPrismicGalleriesContent'),
  ]

  const navigationProps = getNavigationProps(props)

  const followCopy = get(
    props,
    'data.allPrismicAboutContent.nodes[0].data.follow_copy.html'
  )

  const heroCopy = get(props, 'data.prismicServicesContent.data.hero_copy')
  const heroImage = get(
    props,
    'data.prismicServicesContent.data.hero_image.document.data'
  )
  const heroProps = {
    title: heroCopy,
    image: heroImage,
  }

  const introTitle = get(props, 'data.prismicServicesContent.data.intro_title')
  const introCopy = get(
    props,
    'data.prismicServicesContent.data.intro_copy.html'
  ).replace(/(https?:\/\/.\/)/gim, '/')

  const services = get(props, 'data.prismicServicesContent.data.services')

  const generalContent = get(
    props,
    'data.allPrismicGeneralContent.nodes[0].data'
  )

  const socialProps = {
    instagram: navigationProps.instagram,
    facebook: navigationProps.facebook,
    followCopy,
    latestPostsLabel: generalContent.latest_posts_label,
    loadMorePosts: generalContent.load_more_posts,
  }

  const servicesProps = {
    introTitle,
    introCopy,
    services,
    generalContent,
  }

  return (
    <Layout {...{ ...navigationProps, langLinks }}>
      <Helmet {...generalContent} />
      <Hero {...heroProps} compact />
      <Services {...servicesProps} />
      {getExtraSections(sections, generalContent, props.pageContext.lang)}
      <Social {...socialProps} />
    </Layout>
  )
}

export const servicesPageQuery = graphql`
  query($id: String, $lang: String) {
    allPrismicNavigation(filter: { lang: { eq: $lang } }) {
      nodes {
        lang
        data {
          links {
            page {
              uid
              lang
            }
            label
          }
        }
      }
    }
    allPrismicGeneralContent(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          ...generalContent
        }
      }
    }
    allPrismicAboutContent(filter: { lang: { eq: $lang } }) {
      nodes {
        data {
          follow_copy {
            html
          }
        }
      }
    }
    prismicServicesContent(id: { eq: $id }, lang: { eq: $lang }) {
      alternate_languages {
        url
        lang
      }
      data {
        ...servicesContent
        hero_copy
        hero_image {
          document {
            ... on PrismicHeroImage {
              data {
                portrait {
                  fluid(maxWidth: 1650, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
                landscape {
                  fluid(maxWidth: 2800, imgixParams: { q: 100 }) {
                    ...fluidImage
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicGalleriesContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...galleriesContent
        }
      }
    }
    allPrismicTestimonialsContent(filter: { lang: { eq: $lang } }) {
      __typename
      nodes {
        url
        data {
          ...testimonialsContent
        }
      }
    }
  }
`

export default ServicesPage
